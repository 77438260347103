require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")

// global.$ = $; // erbファイルでも利用可能にするためグローバル変数に吐き出す。
global.jQuery = global.$ = require('jquery')

require("jquery"); // Don't really need to require this...
require("jquery-ui");

import "bootstrap";
require("@fortawesome/fontawesome-free");
require("bootstrap-select");
require("bootstrap4-toggle/js/bootstrap4-toggle.min.js");
